import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() {
		this.onResize();
		if (this.screenWidth < 1199) {
			this.closeSidebar = true;
			this.sidebarToggle = false;
		}
	}

	public screenWidth: any;
	public closeSidebar: boolean = false;
	public sidebarToggle: boolean = true;
	public fullScreen = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [];

	// Array
	items = new BehaviorSubject<Menu[]>(this.roleMenu());

	roleMenu() {
		let user = JSON.parse(localStorage.getItem('usuario'));
		
		if (user.rol === "administrador") {/* menu para administrador */
			return this.MENUITEMS = [
				{ path: '/dashboard/default', icon: 'home', title: 'Inicio', type: 'link' },
				{
					title: 'Candidatos', icon: 'users', type: 'sub', active: false, badgeType: 'pill gradient-success', children: [
						{ path: '/candidatos/tabla-candidatos', title: 'Lista Candidatos', type: 'link' },
						{ path: '/candidatos/crear-candidatos', title: 'Crear Candidatos', type: 'link' },
					],
				},
				{
					title: 'Asociados', icon: 'users', type: 'sub', active: false, badgeType: 'pill gradient-success', children: [
						{ path: '/asociados/tabla-asociados', title: 'Lista Asociados', type: 'link' },
						{ path: '/asociados/crear-asociado', title: 'Crear Asociado', type: 'link' },
					],
					
				},
				{
					title: 'Cuotas', icon: 'dollar-sign', type: 'sub', active: false, badgeType: 'pill gradient-success', children: [
						{ path: '/cuotas/tabla-cuotas', title: 'Lista Cuotas', type: 'link' },
						{ path: '/cuotas/crear-cuotas', title: 'Crear Cuota', type: 'link' },
					],
					
				}
			


			];
		} else if(user.rol === "asociado") { /* menu para asociado */
			return this.MENUITEMS = [
				{ path: '/asociados/home', icon: 'home', title: 'Home', type: 'link' },
				{
					title: 'Perfil', icon: 'users', type: 'sub', active: false, badgeType: 'pill gradient-success', children: [
						{ path: '/asociados/mis-datos', title: 'Mis Datos', type: 'link' },
						{ path: '/asociados/estado-cuenta', title: 'Estado de Cuenta', type: 'link' },
					],
				},
			];
		}else{/* sin rol por definir */
			return this.MENUITEMS = [
				{ path: '/dashboard', icon: 'home', title: 'Home', type: 'link' },
			];

		}

	}



}
