import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgxRolesService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from './global-constants';

@Injectable({
  providedIn: 'root'
})
export class CuotasService {

  constructor( private http: HttpClient,) { }

  lcuotas(){
   return  this.http.get(`${GlobalConstants.apiURL}/cuotas/lCuotas`,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });
  }
  cCandidatos(data){
    
   return  this.http.post(`${GlobalConstants.apiURL}/cuotas/cCuotas`,data,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });

  }
  rCandidatos(id){
   return  this.http.get(`${GlobalConstants.apiURL}/cuotas/rCuotas/${id}`,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });
  }

  uCuotas(data){
   return  this.http.post(`${GlobalConstants.apiURL}/cuotas/uCuotas`,data,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });

  }
}
