import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AsociadosService} from '../services/apis/asociados.service';
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    public router: Router,
    public asociados: AsociadosService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem('usuario'));
    let header = null
    if(user != null){
      this.asociados.lApiKey(user.apiKey).subscribe((res:any) => {
        header = res
       if(res === null ){
          localStorage.clear()
          this.router.navigate(['/auth/login']);
          return true
        
       }else if(!user || user === null){
        this.router.navigate(['/auth/login']);
        return true
       }else if(user){
        if (!Object.keys(user).length) {
          this.router.navigate(['/auth/login']);
          return true
        }
       }
      
      })
    }

    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }
    } 

   
    return true
  }
}
