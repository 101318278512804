import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgxRolesService } from 'ngx-permissions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalConstants } from './global-constants';


export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public user: [];
  private _sessionId: string;
  public showLoader: boolean = false;

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private rolesService: NgxRolesService,
    private cookieService: CookieService,
    private http: HttpClient) {

    let user = JSON.parse(localStorage.getItem('usuario'));
    if (user) {
      this.userData = user;
      this._sessionId = this.userData;
      cookieService.set('user', JSON.stringify(this.userData));
      JSON.parse(cookieService.get('user'));
      localStorage.setItem('usuario', JSON.stringify(this.userData));
      JSON.parse(localStorage.getItem('usuario'));
    } else {
      localStorage.setItem('usuario', null);
      JSON.parse(localStorage.getItem('usuario'));
    }
  }

  ngOnInit(): void { }

  //sign in function
  SignIn(usuario, password) {

    if (usuario && password) {
      let params = { usuario, clave: password };
      let user = this.http.post(`${GlobalConstants.apiURL}/user/loginAdministrador`, params);
      this.SendVerificationUser(user);
      this.showLoader = false;
    } else {
      this.showLoader = false;
      this.ngZone.run(() => {
        this.router.navigate(['/auth/login']);
        this.toster.error('Ingresó un correo electrónico o contraseña incorrectos.');
      });
    }
    return;
  }
  //main verification function
  SendVerificationUser(user) {
    user.subscribe(res => {
      if (res.status == '-1') {

        this.toster.error('Ingresó un correo electrónico o contraseña incorrectos.');
        return;
      }
      localStorage.setItem('usuario', JSON.stringify(res));
      if (res.rol=='administrador') {
        this.router.navigateByUrl('/asociados/tabla-asociados');
        return;
      }else{
        this.router.navigateByUrl('/asociados/home');
        return;
      }
    }, erro => {
      console.log(erro)
      this.toster.error('Ingresó un correo electrónico o contraseña incorrectos.');
      return;
    });
  }


  //Set user
  SetUserData(user) {
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified
    };

    return {
      merge: true
    };
  }

  // Sign out
  SignOut() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.showLoader = false;
    localStorage.clear();
    this.cookieService.deleteAll('user', '/auth/login');
    /* this.router.navigate(['/auth/login']); */

    window.location.reload();

  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('usuario'));
    return (user != null && user.name != false) ? true : false;
  }

}
