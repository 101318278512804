import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Compost - Premium Admin Template';
  apiUrl = 'https://ascol2020.nuvoll.co/apis';
}
