import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/apis/auth.service';

type UserFields = 'usuario' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;
  public user: firebase.User;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;

  public formErrors: FormErrors = {
    'usuario': '',
    'password': '',
  };

  public errorMessage: any;

  constructor(
   
    public authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {

    const url = this.route.snapshot.url;
    if (url[0].path === 'simple-login') {
      this.signup = false;
    }
    this.registraionForm = this.fb.group({
      // mobileNumber: [null, [Validators.required]]
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.loginForm = fb.group({
      usuario: ['', [Validators.required]],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {
  }

  public toggle() {
    this.signup = !this.signup;
  }



  // Simple Login
  login(l) {

    this.authService.SignIn(this.loginForm.value['usuario'], this.loginForm.value['password']);
  }

}
