
export class ConfigDB {
	static data = {
	   settings: {
		  layout_type: 'ltr',
		  sidebar: {
			 type: 'default',
			 body_type: 'default'
		  },
		  sidebar_setting: 'border-sidebar',
		  sidebar_backround: 'color3-sidebar'
	   },
	   color: {
		  layout_version: '',
		  color: '',
		  primary_color: '',
		  secondary_color: '',
		  mix_layout: 'light-only',
	   },
	   background_layout: 'default'
	}
 }