<div class="page-main-header" [ngClass]="{'open': headerStrech}">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper"><a [routerLink]="['']" routerLinkActive="router-link-active"><img
            src="assets/images/compost-logo1.png" alt=""></a></div>
    </div>
    <div class="mobile-sidebar d-block">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <input id="sidebar-toggle" type="checkbox" [checked]="navServices.sidebarToggle"><span class="switch-state"
            (click)="switchToggle()"></span>
        </label>
      </div>
    </div>
    <div class="vertical-mobile-sidebar hide"><i class="fa fa-bars sidebar-bar"></i></div>
    <div class="vertical-mobile-sidebar show"><i class="fa fa-bars sidebar-bar"></i></div>

    <div class="nav-right col pull-right right-menu">
      <ul class="nav-menus" [ngClass]="{'open': openNav}">
        <li>
          <div class="dropdown" ngbDropdown>
            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"><span class="media user-header"
                ngbDropdownToggle>
                <div *ngIf="session.foto != null ">
                  <img width="30" height="30" class="mr-2 rounded-circle img-35" [src]="urlimagen+session.foto"
                    alt="foto" title="foto">
                </div>

                <span class="media-body">
                  <span class="f-12 f-w-600"> {{session.nombre}}</span>

                  <span class="d-block">{{session.rol}}</span></span>
              </span></button>
            <div class="dropdown-menu p-0 profile-dropdown" ngbDropdownMenu>
              <ul>
                <li *ngIf="authService.userData as user">
                  <a href="#">
                    <app-feather-icons [icon]="'user'"></app-feather-icons>Perfil
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li>
          <a href="javascript:void(0)" (click)="authService.SignOut()">
            <app-feather-icons [icon]="'log-out'"></app-feather-icons>
          </a>
        </li>
      </ul>
    </div>


  </div>
</div>