import { HttpHeaders } from '@angular/common/http';

export class GlobalConstants {


    //NUVOLL
    // public static apiURL: string = "https://ascol2020.nuvoll.co/apis";
    // public static urlFoto: string = "https://ascol2020.nuvoll.co/apis/private/images/";
    // public static urlArchivos: string = "https://ascol2020.nuvoll.co/apis/private/files/";
    // public static urlTransaccion: string = "https://ascol2020.nuvoll.co/admin/#/asociados/transaccion";

    //PRODUCCION ASCOLCIRUGIA
    public static apiURL: string = "https://apis.ascolcirugia.org";
    public static urlFoto: string = "https://apis.ascolcirugia.org/private/images/";
    public static urlArchivos: string = "https://apis.ascolcirugia.org/private/files/";
    public static urlTransaccion: string = "https://admin.ascolcirugia.org/#/asociados/transaccion";


    public static createRequestOptions(usuario) {
        let headers = new HttpHeaders({
            "Accept": usuario.apiKey,
            "apiKey": usuario.apiKey
        });
        return headers;
    }
}