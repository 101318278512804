import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgxRolesService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from './global-constants';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
const EXCEL_EXT = '.xlsx'

@Injectable({
    providedIn: 'root'
})
export class AsociadosService implements OnInit {
    constructor(private http: HttpClient
    ) {

    }

    ngOnInit(): void { }

    exportar(data: any, name: any): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
        const workbook: XLSX.WorkBook = {
            Sheets: { 'data': worksheet },
            SheetNames: ['data']
        }
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveASExcel(excelBuffer, name)
    }
    private saveASExcel(buffer: any, fileNAme: string) {
        const data: Blob = new Blob([buffer], { type: EXCEL_EXT });
        FileSaver.saveAs(data, fileNAme + '_export' + EXCEL_EXT);
    }


    listasAsociados(estado = null, tiposocio = null, idCiudad = null, especialidades = null) {
        return this.http.post(`${GlobalConstants.apiURL}/asociados/lAsociados`, { "estado": estado, "tiposocio": tiposocio, "idCiudad": idCiudad, "especialidades": especialidades });
    }

    lAsociadosExcel(estado = null, tiposocio = null, idCiudad = null, especialidades = null) {
        return this.http.post(`${GlobalConstants.apiURL}/asociados/lAsociadosExcel`, { "estado": estado, "tiposocio": tiposocio, "idCiudad": idCiudad, "especialidades": especialidades }, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }
    rAsociados(id) {
        return this.http.get(`${GlobalConstants.apiURL}/asociados/rAsociados/${id}`);
    }
    cCuentaPayu(data) {
        return this.http.post('https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/', data);
    }

    lCiudades() {
        return this.http.get(`${GlobalConstants.apiURL}/ciudad/lCiudades`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lCiudadesASociados(id) {
        return this.http.get(`${GlobalConstants.apiURL}/ciudad/rCiudadPais/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }
    lEspecialidades() {
        return this.http.get(`${GlobalConstants.apiURL}/asociados/lEspecialidades`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }
    lPaises() {
        return this.http.get(`${GlobalConstants.apiURL}/paiese/lPaises`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lEducacion(id) {
        return this.http.get(`${GlobalConstants.apiURL}/educacion/lEducacion/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lPracticaInstitucional(id) {
        return this.http.get(`${GlobalConstants.apiURL}/practicaInstitucionales/lPracticaInstitucionales/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lPracticaPrivada(id) {
        return this.http.get(`${GlobalConstants.apiURL}/practicaPrivada/lPracticaPrivada/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lPracticaAcademica(id) {
        return this.http.get(`${GlobalConstants.apiURL}/practicaAcademicas/lPracticaAcademicas/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lAsociadoUser(id) {
        return this.http.get(`${GlobalConstants.apiURL}/user/rAsociado/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    uAsociados(data) {
        return this.http.post(`${GlobalConstants.apiURL}/asociados/uAsociados`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    cAsociados(data) {
        return this.http.post(`${GlobalConstants.apiURL}/asociados/cAsociadoAdministrador`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    uEducacion(data) {
        return this.http.post(`${GlobalConstants.apiURL}/educacion/uEducacion`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    cEducacion(data) {
        return this.http.post(`${GlobalConstants.apiURL}/educacion/cEducacionAdministrador`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }


    uPracticaInstitucional(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaInstitucionales/uPracticaInstitucionales`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    uPracticaPrivada(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaPrivada/uPracticaPrivada`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }


    cPracticaInstitucional(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaInstitucionales/cPracticaInstitucionalesAdministrador`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    cPracticaPrivada(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaPrivada/cPracticaPrivadaAdministrador`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }



    uPracticaAcademica(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaAcademicas/uPracticaAcademicas`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }
    cPracticaAcademica(data) {
        return this.http.post(`${GlobalConstants.apiURL}/practicaAcademicas/cPracticaAcademicasAdministrador`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    cUser(data) {
        return this.http.post(`${GlobalConstants.apiURL}/user/cUser`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    uUser(data) {
        return this.http.post(`${GlobalConstants.apiURL}/user/uUser`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    lCuotasPendientes(id) {
        return this.http.get(`${GlobalConstants.apiURL}/cuotas/lCuotasPendientes/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lApiKey(id) {
        return this.http.get(`${GlobalConstants.apiURL}/user/lApikey/${id}`);
    }

    lCuotasRealizadas(id) {
        return this.http.get(`${GlobalConstants.apiURL}/cuotas/lCuotasRealizadas/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    uEstado_cuenta(data) {
        return this.http.post(`${GlobalConstants.apiURL}/estado_cuenta/uEstado_cuenta`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    cEstado_cuenta(data) {
        return this.http.post(`${GlobalConstants.apiURL}/estado_cuenta/cEstado_cuenta`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }
    //RAFAEL 7 DE DICIEMBRE
    //INSERTA EL PAGO MANUAL POR PARTE DE ADINITRADOR


    cPagoCuotaManual(data) {
        return this.http.post(`${GlobalConstants.apiURL}/estado_cuenta/cPagoCuotaManual`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    uPagoCuotaManual(data) {
        return this.http.post(`${GlobalConstants.apiURL}/estado_cuenta/uPagoCuotaManual`, data, {
            headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
        });
    }

    lEstado_cuenta(id) {
        return this.http.get(`${GlobalConstants.apiURL}/estado_cuenta/lEstado_cuenta_usuario/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    lCuotasNoAsignadas(id) {
        return this.http.get(`${GlobalConstants.apiURL}/cuotas/lCuotasNoAsignadas/${id}`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

    //Listado de los tipos desocios
    tSocios() {
        return this.http.get(`${GlobalConstants.apiURL}/asociados/tSocios`,
            {
                headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
            });
    }

}
