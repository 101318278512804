<div class="container-fluid p-0">
    <!-- login page start-->
    <div class="authentication-main mt-0 login-p">
        <div class="row">
            <div class="col-md-6">
                <img src="/assets/images/ascolImagen.jpg" alt="" class="login-img">
            </div>
            <div class="col-md-6">
                <div class="auth-innerright auth-bg">
                    <div class="authentication-box">
                        <div class="mt-4">
                            <div class="card-body p-0">
                                <div class="cont text-center">
                                    <div>
                                        <form class="theme-form" [formGroup]="loginForm">
                                            <p class="text-titulo">Hola! Bienvenidos</p>
                                            <hr>
                                            <h6>Ingrese Usuario y Contraseña</h6>


                                            <div class="form-group text-left">
                                                <label class="col-form-label pt-0">Usuario</label>
                                                <input class="form-control" formControlName="usuario" type="text" required="">
                                                <div *ngIf="loginForm.controls.usuario.touched && loginForm.controls.usuario.errors?.required" class="text text-danger mt-1">
                                                    Usuario requerido
                                                </div>

                                            </div>



                                            <div class="form-group text-left">
                                                <label class="col-form-label pt-0">Contraseña</label>
                                                <input class="form-control" formControlName="password" type="password" required="">
                                                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                                                    Contraseña requerida
                                                </div>
                                            </div>

                                            <div class="form-group text-left">
                                                <input id="checkbox1" type="checkbox">
                                                <label class="col-form-label pt-0 pl-5">Recuérdame</label>

                                            </div>


                                            <div class="form-group form-row mt-3 mb-0">
                                                <button class="btn btn-secondary btn-block btn-lg" [class.loader--text]="authService.showLoader" [disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)" type="submit"><span>{{ authService.showLoader ? '' : 'Iniciar Sesión' }}</span>
												</button>
                                            </div>
                                            <br>
                                            <hr>
                                            <small class="text-primary">Ver. 2.9 - 2024 - Nuvoll Desarrollo Web</small>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- login page end-->