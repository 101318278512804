import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgxRolesService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from './global-constants';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
const EXCEL_EXT = '.xlsx'

@Injectable({
  providedIn: 'root'
})
export class CandidatosService {

  constructor( private http: HttpClient,) { }

  lcandidatos(){
   return  this.http.get(`${GlobalConstants.apiURL}/asociados/lCandidatos`,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });
  }

  lCandidatosExcel(){
    return this.http.get(`${GlobalConstants.apiURL}/asociados/lCandidatosExcel`, {
        headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
    });
  }


  exportar(data:any, name:any): void {
    const worksheet : XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
    const workbook : XLSX.WorkBook = { 
        Sheets: {'data' : worksheet},
        SheetNames: ['data']
     }
     const excelBuffer:any = XLSX.write( workbook, { bookType : 'xlsx' , type: 'array'});
     this.saveASExcel(excelBuffer,name )
   }
   private saveASExcel(buffer:any , fileNAme:string){
       const data: Blob = new Blob([buffer], {type: EXCEL_EXT});
       FileSaver.saveAs(data,fileNAme + '_export'+ EXCEL_EXT);
   }


  lciudades(){
   return  this.http.get(`${GlobalConstants.apiURL}/ciudad/rCiudadPais/48`,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });
  }
  cCandidatos(data){
    
   return  this.http.post(`${GlobalConstants.apiURL}/asociados/cAsociadoCantidatos`,data,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });

  }
  rCandidatos(id){
   return  this.http.get(`${GlobalConstants.apiURL}/asociados/rAsociados/${id}`,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });
  }

  uCandidatos(data){
   return  this.http.post(`${GlobalConstants.apiURL}/asociados/uAsociados`,data,  {
    headers: GlobalConstants.createRequestOptions(JSON.parse(localStorage.getItem('usuario')))
   });

  }
}
