import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: 'Dashboard'
    }
  },
  {
    path: 'asociados',
    loadChildren: () => import('../../components/asociados/asociados.module').then(m => m.AsociadosModule),
    data: {
      title: "Asociados",
      breadcrumb: "Asociados"
    }
  },
  {
    path: 'candidatos',
    loadChildren: () => import('../../components/candidatos/candidatos.module').then(m => m.CandidatosModule),
    data: {
      title: "Candidatos",
      breadcrumb: "Candidatos"
    }
  },
  {
    path: 'cuotas',
    loadChildren: () => import('../../components/cuotas/cuotas.module').then(m => m.AsociadosModule),
    data: {
      title: "Cuotas",
      breadcrumb: "Cuotas"
    }
  },
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample-page/sample-page.module').then(m => m.SamplePageModule),
    data: {
      title: "Sample-Page",
      breadcrumb: "Sample-Page"
    }
  },
  {
    path: 'user',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      title: "Users",
      breadcrumb: "Users"
    }
  }
];
